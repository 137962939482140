
/*	PROD	*/

.objectifs-screen {
  width: 100vw;
  height: 100vh;
  margin: auto;
}

.objectifs-screen .field-icon .icon {
	width: 100%;
}

.objectifs-screen .landscape-view .fied-resum .objectifs-value {
	padding: 40px;
}
