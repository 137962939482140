
/*	PROD	*/

.list-select-component {
  width: 100%;
	border: 2px solid #bdbdbd;
	border-radius: 5px;
	margin: 10px 0 0;
	padding: 15px 10px;
}



.list-select-component .list-data-container {
  position: fixed;
  top: var(--start-y, 50%);
  left: var(--start-x, 50%);
  width: 0;
  height: 0;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  transform-origin: top left;
  animation: expand-to-top-left 0.6s ease-out forwards;
  z-index: 1000;
}

@keyframes expand-to-top-left {
  from {
    top: var(--start-y);
    left: var(--start-x);
    width: 0;
    height: 0;
    opacity: 0;
  }
  to {
    top: 0;
    left: 0;
    width: 100vw;
    height: 80vh;
    opacity: 1;
  }
}



.select-entry-screen .list-select-component .view-list-data .btn-element-list {
	width: calc(100% - 10px);
	min-height: 4em;
	margin: 5px;
}
.select-entry-screen .list-select-component .view-list-data .btn-element-list img {
	width: 10%;
	margin-right: 15px;
}
