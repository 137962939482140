
/*	PROD	*/

.search-entry-component {
  width: 100%;
  position: relative;
}

.search-entry-component .container-champs-search {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 10px;
}

.search-entry-component .container-champs-search .search-input:focus {
  border-bottom: 1px solid #45B40B !important;
  box-shadow: 0 1px 0 0 #45B40B !important;
}
.search-entry-component .container-champs-search .search-icon-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 10px;
  display: flex;
  align-items: center;
}

.search-entry-component .container-champs-search .search-icon-button .search-icon {
  width: 20px;
  height: 20px;
}

.search-entry-component .container-list-search {
  position: absolute;
  width: 100%;
}

.search-entry-component .container-list-search .b_element_list {
  width: 100%;
  text-align: left;
  cursor: pointer;
}
