
/* PROD */

.recup-mail-screen {
  width: 100vw;
  height: 95vh;
  margin: auto;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
}
.recup-mail-screen .field-vignette .logo-appli ,
.recup-mail-screen .field-vignette .logo-perso {
  width: 100%;
}
.recup-mail-screen .portrait-view .field-vignette .logo-appli,
.recup-mail-screen .portrait-view .field-vignette .logo-perso {
  margin-top: 20%;
}
.recup-mail-screen .landscape-view .field-vignette .logo-appli {
  margin-top: 50%;
}
.recup-mail-screen .field-principal-container .field-acceuil .field-input {
  margin-top: 10%;
}
.recup-mail-screen .field-principal-container .field-acceuil .field-send {
  margin-top: 20%;
}
