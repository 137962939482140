
/*	PROD	*/

.signature-component .field-signature canvas {
  border: 1px solid #000;
  border-radius: 5px;
  width: 90%;
  height: 160px; 
  margin-top: 30px;
	margin-left: calc(10% / 2);
}
