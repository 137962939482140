
/*	PROD	*/

.control-prop-screen {
  width: 100vw;
  height: 95vh;
  margin: auto;
}

.control-prop-screen .field-vignette .vignette,
.control-prop-screen .field-footer .logo {
  width: 100%;
}

.control-prop-screen .field-objectifs .statistiques-component-value,
.control-prop-screen .field-plans-actions .statistiques-component-value {
	margin: 40px;
}
