
/*	PROD	*/

.content-input-component {
  position: relative;
  border-radius: 50px;
  background-color: whitesmoke;
}
.content-input-component label {
  display: block;
  position: absolute;
  left: 30px;
  font-size: 10pt;
}
.content-input-component .champs-input-component {
  margin-left: 10%;
  width: 80%;
  margin-top: 10px;
}
