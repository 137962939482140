
/* PROD */

.login-screen {
  width: 100vw;
  height: 95vh;
  margin: auto;
  border-radius: 5px;
}
.login-screen .field-vignette .logo-appli,
.login-screen .field-vignette .logo-vignette {
  width: 100%;
}
.login-screen .portrait-view .field-vignette .logo-appli,
.login-screen .portrait-view .field-vignette .logo-vignette {
  margin-top: 20%;
}
.login-screen .landscape-view .field-vignette .logo-appli {
  margin-top: 50%;
}
