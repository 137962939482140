
/*	PROD	*/

.config-prop-screen {
	width: 100vw;
	height: 100vh;
}

.config-prop-screen .field-icone img,
.config-prop-screen .field-vignette img {
	width: 100%;
	margin-top: 10px;
}
