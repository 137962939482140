
/* PROD */

.acceuil-screen {
  width: 100vw;
  height: 95vh;
  margin: auto;
  border-radius: 5px;
}
.acceuil-screen .field-vignette .logo-appli,
.acceuil-screen .field-vignette .vignette {
  width: 100%;
}
.acceuil-screen .portrait-view .field-vignette .logo-appli,
.acceuil-screen .portrait-view .field-vignette .vignette {
  margin-top: 10%;
}
.acceuil-screen .field-principal .btn-appli {
  width: 100%;
}
.acceuil-screen .field-principal .btn-appli.no-abonnement {
  opacity: 0.5;
}
