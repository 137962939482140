
/*	PROD	*/

.select-agent-dial,
.select-agent-dial-content {
  height: 100%;
}

.select-agent-dial-content .btn {
  width: 50%;
}

.select-agent-dial-content .list-content {
  height: 90%;
  overflow-y: scroll;
}
.select-agent-dial-content .list-content .btn {
  width: 100%;
  text-align: left;
  padding-left: 10px;
}
