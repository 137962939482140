
html, body, #root, .app-body {
  width: 100vw;
  height: 95vh;
  margin: 0;
  padding: 0;
}
.landscape-warning {
  display: none;
}
.btn-std {
  background-color: #45B40B !important;
  color: white;
  border-radius: 5px;
}
.btn-bad {
  background-color: red !important;
  color: white;
  border-radius: 5px;
}
.btn-list {
  background-color: white !important;
  color: #45b40b;
  height: unset;
  line-height: unset;
  padding: 5px;
  min-height: 54px;
}
.td-center {
  text-align: center;
}
