
/* PROD */

.profil-screen {
  width: 100vw;
  height: 95vh;
  margin: auto;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
}
.profil-screen .field-vignette .logo-appli,
.profil-screen .field-vignette .vignette {
  width: 100%;
}
