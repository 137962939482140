
/*	PROD	*/

.menu-onglet-component {
  height: 100%;
}

.menu-onglet-component .btn-vertical {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
}

.menu-onglet-component .drawer {
  position: fixed;
  top: 75px;
  left: 40px;
  height: 75%;
  width: 0;
  background: #ffffff;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  transition: transform 0.5s ease, width 0.5s ease;
  z-index: 1000;
}
.menu-onglet-component .drawer.open {
  transform: translateX(0);
  width: calc(100% - 40px);
}
.menu-onglet-component .drawer-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.menu-onglet-component .drawer-container .send-rapport-component .field-desodre .checkbox-component {
	padding-left: 30px;
}
