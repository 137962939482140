
/*	PROD	*/

.grille-control-mobile-screen {
	width: 100%;
	height: 100%;
}

.grille-control-mobile-screen .field-applis .menu-appli-view-container img,
.grille-control-mobile-screen .field-vignette img {
	width: 100%;
}
.grille-control-mobile-screen .grille-zone-view .btn-open-zone img {
	width: 50%;
}
