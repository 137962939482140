
/*	PROD	*/

.mini-stat-component {
  width: 100%;
}

.mini-stat-component .line-component .label-component,
.mini-stat-component .line-component .value-component {
  display: inline-block;
  vertical-align: top;
  font-weight: bold;
}
.mini-stat-component .line-component .label-component {
  color: #bdbdbd;
  width: 50%;
}
