
/*	PROD	*/

.typage-ctrl-component {
	margin: 5px;
}

.typage-ctrl-component button {
	width: 100%;
	height: 4em;
}
