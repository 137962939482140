
/*	PROD	*/

.modal-dials-content .comment-container .text-input-comment {
  height: 10rem;
  resize: none;
  padding: 5px;
}
.modal-dials-content .comment-container .btn-clear-comment {
  margin-left: 75%;
  margin-bottom: 20px;
}

.modal-dials-content .comment-container .btn-show-capture {
  width: 100%;
}

.modal-dials-content .comment-container .webcam-container .btn-take-picture {
  margin-left: 65%;
}
.modal-dials-content .comment-container .webcam-container .view-capture-container img {
  margin-left: 2%;
}
.modal-dials-content .comment-container .webcam-container .view-capture-container .btn-valid-capture-container {
  margin-left: 25%;
}
.modal-dials-content .comment-container .webcam-container .view-capture-container .btn-suppr-capture-container {
  margin-left: 60%;
}
.modal-dials-content .comment-container .webcam-container .view-capture-container .btn {
  margin-left: 5px;
}

.modal-dials-content .comment-container .cont-btn-comment {
  margin-top: 20px;
  text-align: right;
}
.modal-dials-content .comment-container .cont-btn-comment .btn {
  margin-left: 5px;
}
